import i18n from '../i18n/i18n';

export default function ISODateToLocaleDate(ISOstring) {
  const date = new Date(ISOstring);
  const options = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    timeZone: 'UTC',
  };
  return date.toLocaleString(i18n.language.split('-')[0], options);
}
